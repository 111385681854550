import { captureException, captureMessage } from 'isomorphic-sentry';

export default class BlackhawkService {
  public static async getAvailableCards(productIds: string[]) {
    const data = {
      "productIds": productIds
    };
    let response;
    try {
      response = await this.doRequest('/product-catalog', JSON.stringify(data));
    } catch (e) {
      captureException(e);
      return e;
    }
    return response;
  }

  static async doRequest (path: string, body?: any) {
    const server = typeof window === 'undefined' ? process.env.BLACKHAWK_HOST : '';
    const headers = new Headers({
      "Accept": 'application/json',
      "Content-Type": 'application/json',
      "token": process.env.TOKEN || ''
    });
    try {
      const response = await fetch(`http://${server}${path}`,
        {
          method: body ? 'POST' : 'GET',
          headers,
          body,
          credentials: 'same-origin'
        }).then(r => r.json());
      return response;
    } catch (error) {
      captureMessage(`unable to get gift cards, http://${server}${path} ${error}`);
      return error;
    }
  }
}
