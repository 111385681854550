import { captureException } from 'isomorphic-sentry';
import { addDays, format } from 'date-fns';

export default class AccommodationService {
  public static generateBookingLink(
    bookingId: number | null,
    parent: string,
    adults = 2,
    from: Date = new Date(),
    to: Date = addDays(new Date(), 2)
  ) {
    return `https://app-apac.thebookingbutton.com/properties/${parent}/book?check_in_date=${format(from, 'yyyy-MM-dd')}&check_out_date=${format(to, 'yyyy-MM-dd')}&locale=en&currency=AUD&items[0][adults]=${adults}&items[0][children]=0&items[0][infants]=0&items[0][rateId]=${bookingId}&step=step1&promocode=membersonly`;
  }

  public static async loadProperties () {
    return await this.doRequest('getProperties');
  }

  public static async loadProperty (slug: string) {
    return await this.doRequest(`getProperty?slug=${slug}`);
  }

  // returns array of the suburbs
  public static async getLocations () {
    return await this.doRequest('getLocations');
  }

  private static async doRequest (path: string) {
    const url = `http://${process.env.NGINX_HOST}/accom/${path}`;

    try {
      const response = await fetch(url)
        .then(r => r.json());
      return response;
    } catch (error) {
      captureException(error);
      return error;
    }
  }
}
