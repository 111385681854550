import UserService from './UserService';
import { captureException } from 'isomorphic-sentry';

export default class FuseBillService {
  public static async getSubscriptionTypes() {
    return await this.doRequest('plan', 'GET');
  }

  public static async getBillingStatus() {
    return await this.doRequest('status', 'GET');
  }

  public static async createPaymentMethod(formData: string) {
    const user = await UserService.getUserData();

    if (!user) return false;
    const riskToken = window?.WePay?.risk?.get_risk_token();
    let ip = '0.0.0.0';
    try {
      const response = await fetch('https://icanhazip.com/').then(r => r.text());
      if (response) {
        ip = response.replace('\n', '');
      }
    } catch {
      console.warn('unable to gather ip');
    };

    const data = `CustomerID=${user.fb}&email=${user.email}&address1=${user.address}&city=${user.suburb}&postalZip=${user.postcode}&riskToken=${riskToken}&clientIP=${ip}&makeDefault=true&${formData}PublicAPIKey=48c55a09-c70d-49fa-a541-21663ea50bff`;
    const url = `https://payments.subscriptionplatform.com/api/paymentsv2/?${data}`; // ajax transparent Url
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers:{
          'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Length': data.length.toString()
        },
        body: data
      }).then(r => r.json());
      return response;
    } catch (error) {
      captureException(error);
      return false;
    }
    // TODO: ERROR
    return false;
  }

  public static async updatePaymentMethod(formData: string) {
    const user = await UserService.getUserData();
    const riskToken = window?.WePay?.risk?.get_risk_token();
    let ip = '0.0.0.0';
    try {
      const response = await fetch('https://icanhazip.com/').then(r => r.text());
      if (response) {
        ip = response.replace('\n', '');
      }
    } catch {
      console.warn('unable to gather ip');
    };

    if (!user) return false;
    const data = `CustomerID=${user.fb}&Id=${user.fbpid}&address1=${user.address}&city=${user.suburb}&postalZip=${user.postcode}&email=${user.email}$&riskToken=${riskToken}&clientIP=${ip}&makeDefault=true&${formData}PublicAPIKey=48c55a09-c70d-49fa-a541-21663ea50bff`;
    const url = `https://payments.subscriptionplatform.com/api/paymentsv2/?${data}`; // ajax transparent Url
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers:{
          'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Length': data.length.toString()
        },
        body: data
      }).then(r => r.json());
      return response;
    } catch (error) {
      captureException(error);
      return false;
    }
    // TODO: ERROR
    return false;
  }

  static async doRequest (path: string, method: string) {
    const server = typeof window !== 'undefined' ? window.location.host : process.env.NGINX_HOST;
    const protocol = typeof window !== 'undefined' ? window.location.protocol : 'http:';

    try {
      const response = await fetch(
        `${protocol}//${server}/fusebill/${path}`,
        {
          method: `${method}`,
        }).then(r => r.json());
      return response;
    } catch (error) {
      captureException(error);
      return false;
    }
  }
}
