export default class DirectionsService {
  public static async getDistance(origin: string, destination: string) {
    if (typeof window !== 'undefined') {
      const service = new window.google.maps.DistanceMatrixService();
      return new Promise<any>((resolve, reject) => {
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: 'DRIVING',
          },
          (results: any, status: any) => {
            if (status === 'OK') {
              resolve(results);
            } else {
              reject(results);
            }
          }
        );
      });
    }
    return false;
  }
}
