import { captureException } from 'isomorphic-sentry';
import Csrf from 'lib/Csrf';

export default class SproutForm {
  public static async sendForm(formData: FormData, eventText: string) {
    const response = await this.submit(formData);
    if (response.errors) {
      return response;
    }
    if (window && window.dataLayer) {
      window.dataLayer.push({ event: eventText });
    }
    return response;
  }

  private static async submit(formData: FormData) {
    const body = formData;
    const csrf = await Csrf.getCsrf();
    body.append('CRAFT_CSRF_TOKEN', csrf);

    try {
      const response = await fetch('/sproutForms', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'X-Requested-With':'XMLHttpRequest',
          Accept: 'application/json'
        },
        body
      }).then(r => r.json());
      return response;
    } catch (error) {
      captureException(error);
      return false;
    }
  }
}
