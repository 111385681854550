import Csrf from './Csrf';
import * as Sentry from 'isomorphic-sentry';

export default class CommerceService {
  static addToCart = async (order: {key: string; item: CMSVariant}[], user: UserProfileData) => {
    const formData = new FormData();
    const csrf = await Csrf.getCsrf();
    formData.append('CRAFT_CSRF_TOKEN', csrf);
    formData.append('email', user.email);
    formData.append('shippingAddress[firstName]', user.firstName);
    formData.append('shippingAddress[lastName]', user.lastName);
    formData.append('shippingAddress[address1]', user.address);
    formData.append('shippingAddress[countryId]', '13');
    formData.append('shippingAddress[phone]', user.phone);
    formData.append('billingAddressSameAsShipping', '1');
    formData.append('sameAddress', '1');
    formData.append('gatewayId', '2');

    // loop over all products and make items[index]
    let index = 0;
    order.forEach((p: {key: string; item: CMSVariant}) => {
      index += 1;
      formData.append(`purchasables[${index}][id]`, `${p.item.id}`);
      formData.append(`purchasables[${index}][options][card-type]`, `${p.item.parent?.title}`);
      formData.append(`purchasables[${index}][options][card-value]`, `${p.item.fullValue}`);
      formData.append(`purchasables[${index}][qty]`, '1');
    });

    try {
      await fetch('actions/commerce/cart/update-cart', {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'X-Requested-With':'XMLHttpRequest' },
        body: formData
      })
        .then(r => r.json());
    } catch (e) {
      Sentry.captureException(e);
      return e;
    }

    return await CommerceService.getCart();
  }

  static clearCart = async (lineItems: any) => {
    const formData = new FormData();
    const csrf = await Csrf.getCsrf();
    formData.append('CRAFT_CSRF_TOKEN', csrf);

    lineItems.forEach((li: any) => {
      formData.append(`lineItems[${li.id}][remove]`, 'true');
    });

    try {
      await fetch('actions/commerce/cart/update-cart', {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'X-Requested-With':'XMLHttpRequest' },
        body: formData
      })
        .then(r => r.json());
    } catch (e) {
      Sentry.captureException(e);
      return e;
    }

    return await CommerceService.getCart();
  }

  static getCart = async () => {
    const body = new FormData();
    const csrf = await Csrf.getCsrf();
    body.append('CRAFT_CSRF_TOKEN', csrf);
    try {
      const response = await fetch('actions/commerce/cart/get-cart', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'X-Requested-With':'XMLHttpRequest',
          'Accept': 'application/json'
        },
        body
      })
        .then(r => r.json());
      return response;
    } catch (e) {
      Sentry.captureException(e);
      return e;
    }
  }

  static sendPayment = async (
    paymentId: string
  ) => {
    try {
      const formData = new FormData();
      const csrf = await Csrf.getCsrf();
      formData.append('CRAFT_CSRF_TOKEN', csrf);
      formData.append('paymentMethodId', paymentId);

      const response =  await fetch('actions/commerce/payments/pay', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'X-Requested-With':'XMLHttpRequest',
          'Accept': 'application/json'
        },
        body: formData,
      }).then(r => r.json());
      return response;
    } catch (e) {
      Sentry.captureException(e);
      return e;
    }
  }
}

