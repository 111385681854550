import React from 'react';
import { ApolloError } from 'apollo-client';

import ErrorPage from '../pages/_error';

export default (error?: ApolloError | string) => {
  if (error || (process as any).browser) {
    return <ErrorPage statusCode={error ? 500 : 404} error={error} />;
  }

  const err = new Error();
  (err as any).code = 'ENOENT';
  throw err;
};
