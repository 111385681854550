import React, { useMemo } from 'react';
import { useServerContext } from 'next-server-context';
import { useEffectOnce } from 'react-use';
import { captureException } from 'isomorphic-sentry';

interface Props {
  statusCode?: number;
  error?: any;
  message?: string;
}

const ErrorPage = ({ statusCode, error, message }: Props) => {
  const realStatusCode = useMemo(() => statusCode || (error ? 500 : 404), [statusCode, error]);
  const serverContext = useServerContext();
  // istanbul ignore else
  if (serverContext) {
    serverContext.response.statusCode = realStatusCode;
  }

  useEffectOnce(() => {
    if (error) {
      // istanbul ignore else
      if (statusCode !== 404) {
        captureException(error);
      }

      /* istanbul ignore next */
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
    }
  });

  const defaultMessage = useMemo(() => realStatusCode === 404 ? 'Page Not Found' : 'Something Went Wrong', [realStatusCode]);

  return (
    <div className="errorContainer">
      <h1>{realStatusCode}</h1>
      <h2 style={{ textAlign: 'center' }}>{message || defaultMessage}</h2>
      <br />
    </div>
  );
};

export default ErrorPage;
